<template>
  <ui-button
    class="btn-comp"
    v-show="!userPlatform"
    :color="props.color"
    :size="props.size"
    :class="{'g-shadow': $slots.default, 'd-btn': $slots.default}"
    @click="handleButtonClick()"
    :disabled="isDisabled && user.isLoggedIn"
  >
    <template #before>
      <template v-if="props.isSeeSize">
        <div v-if="color === 'primary'" class="over-button-text">
          {{ $t('240Mb') }}
        </div>
        <div v-else class="under-button-text">
          {{ $t('240Mb') }}
        </div>
      </template>
      <slot/>
    </template>
    {{ $t('freeDownload') }}
  </ui-button>
</template>

<script lang="ts" setup>
import {ref, computed, onBeforeMount} from 'vue';
import {useI18n} from 'vue-i18n';
import {useLauncherInfoStore} from '~/store/launcher-info';
import {useRuntimeConfig} from '#imports';
import UiButton from '~/components/Ui/UiButton.vue';
import {useUserStore} from '~/modules/User/user.store';
import {ButtonSizeType} from '~/interfaces/ui/button';
import {useBaseStore} from '~/modules/BaseModule/base.store';
import {goTo} from '~/helpers/baseFunction';

const props = withDefaults(
  defineProps<{
    // label?: string;
    isDownload?: false,
    labelNotAuth?: string;
    addVersionToLabel?: boolean;
    color?: string;
    size?: ButtonSizeType;
    isSeeSize?: boolean;
    isGoToTariff?: boolean;
  }>(),
  {
    // label: 'freeDownload',
    addVersionToLabel: true,
    color: 'primary',
    size: ButtonSizeType.big,
    isSeeSize: true,
    isDownload: false,
    isGoToTariff: false,
  },
);

const emits = defineEmits(['actionAfterDownload']);

const base = useBaseStore();
const user = useUserStore();
const {t} = useI18n();

// const labelText = computed(
//   () =>
//     `${t(
//       !user.isLoggedIn
//         ? props.labelNotAuth || 'loginAndDownload'
//         : props.label,
//     )}${
//       props.addVersionToLabel && launcherInfo.version
//         ? ` (${launcherInfo.version})`
//         : ''
//     }`,
// );
const isDisabled = ref(true);
const launcherInfo = useLauncherInfoStore();
const userPlatform = ref(false);

const getUserPlatform = () => {
  if (typeof navigator !== 'undefined') {
    return (
      /Android/.test(navigator.userAgent) ||
      /(iPhone|iPad|iPod)/.test(navigator.userAgent)
    );
  }
  return false;
};

if (process.client) {
  userPlatform.value = getUserPlatform();
}

launcherInfo.$subscribe((_, state) => {
  isDisabled.value = !(
    state.releaseDate &&
    state.version &&
    state.path &&
    state.size &&
    state.sha512
  );
});

onBeforeMount(() => {
  isDisabled.value = !(
    launcherInfo.sha512 &&
    launcherInfo.releaseDate &&
    launcherInfo.version &&
    launcherInfo.path &&
    launcherInfo.size
  );
});
const runtimeConfig = useRuntimeConfig();
const download = () => {
  base.downloadInstaller();
};

const handleClick = () => {
  if (user.isLoggedIn) {
    download();
    emits('actionAfterDownload');
  } else {
    user.showLoginModal();
  }
};

function handleButtonClick() {
  if (props.isDownload) {
    download();
  } else {
    const tariffElement = document.getElementById('tariff');
    tariffElement ? goTo('#tariff') : download();
  }
}

defineExpose({
  handleClick,
});
</script>
<style lang="scss" scoped>
.over-button-text {
  font-weight: 400;
  font-size: 14px;
  color: #839aaf;
  margin: 0 auto;
  position: absolute;
  top: -24px;
}

.under-button-text {
  font-weight: 400;
  font-size: 14px;
  color: #839aaf;
  margin: 0 auto;
  position: absolute;
  bottom: -24px;
}

.btn-comp {
  border-radius: 8px;
  padding-right: 32px;
  padding-left: 32px;
  font-weight: 600;
}

.button.big {
  padding: 10px 20px !important;
}

.g-shadow {
  box-shadow: 0 3px 10px rgba(36, 145, 231, 0.25);
}
</style>
